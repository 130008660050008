import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import UnsubscribeForm from "./UnsubscribeForm";
import SubscribeForm from "./SubscribeForm";
import Text from "./common/Text";
import { useStores } from "../stores";
import appStyles from "../../../config/appStyles.js";

const useStyles = makeStyles((theme) => appStyles.emailCommunication(theme));

const EmailComunication = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(["components"]);
  const { userStore } = useStores();
  const { user, subscriptions } = userStore;

  const homePage = t("components:Footer.homePage", {
    returnObjects: true,
    defaultValue: {},
  });

  useEffect(() => {
    if (user.email && user.UID) {
      userStore.fetchSubscriptions(user.email, user.UID);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [user.email, user.UID]);

  return (
    <>
      <div id={props.id + "-emailComunication"} className={classes.root}>
        <div id={props.id + "-emailComunication-subscribe"}>
          <Text className={classes.sectionTitle}>
            {t("components:EmailComunication.title")}
          </Text>
          <UnsubscribeForm
            preferences={user && user.preferences}
            email={user.email}
            uid={user.UID}
            redirectUrl={homePage ? homePage.href : ""}
            firstName={user.name}
            lastName={user.lastName}
            hideUserInfo
            hideActions
            stayInMyArea
            subsActive={subscriptions}
            extraAction={() => {
              props.onEdit(false);
            }}
            disabled={!subscriptions?.newsletter}
          />
        </div>
        {!subscriptions?.newsletter && (<div id={"Subscription-emailComunication-subscribe"}>
          <SubscribeForm
            email={user.email}
            uid={user.UID}
            redirectUrl={homePage ? homePage.href : ""}
            firstName={user.name}
            lastName={user.lastName}
            stayInMyArea
            disabled={false}
          />
        </div>)}
      </div>
    </>
  );
});

export default EmailComunication;
