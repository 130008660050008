export const packageHeader = (theme) => ({
  fieldTitle: {
    color: theme.customPalette.neutralGr04,
  },
});

export const packageItemCard = (theme) => ({
  itemCard: {
    background: 'inherit',
    minHeight: '220px',
    textAlign: 'center',
    border: 'none',
    backgroundColor: theme.customPalette.neutralGr01,
  },
  itemCardModel: {
    background: 'inherit',
    minHeight: '220px',
    textAlign: 'center',
    border: 'none',
    backgroundColor: theme.customPalette.neutralGr01,
    marginLeft: '4px',
    marginRight: '4px',
  },
  itemCardMedia: {
    height: '160px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  itemCardMediaMissing: {
    height: '160px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    opacity: '.4',
    filter: 'grayscale(100%)',
  },
  itemCardMediaPlaceholder: {
    margin: '0 auto',
  },
  name: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: '500',
    color: theme.customPalette.neutralGr05,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: 16,
      height: 16,
    },
  },
  sequence: {
    fontSize: '10px',
    fontWeight: '500',
    color: theme.customPalette.neutralGr04,
    lineHeight: 1,
  },
  cardPadding: {
    paddingTop: '8px',
    paddingBottom: '0 !important',
    paddingLeft: 16,
    paddingRight: 16,
  },
  magazine: {
    width: '100%',
    marginTop: 16,

    '& .MuiButton-text': {
      padding: '0',
    },
  },
  markedBtn: {
    marginTop: 16,
    backgroundColor: 'transparent',
    color: theme.customPalette.secondaryYellowYButtons,
    fontSize: 12,
    border: 'None',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  markedBtnActive: {
    textTransform: 'uppercase',
    fontFamily: 'Poppins',
    display: 'block',
    marginTop: 16,
    fontSize: 12,
    lineHeight: 1,
    fontWeight: 100,
    borderBottom: '2px solid' + theme.customPalette.neutralGy05,
  },
});

export const nextPackage = (theme) => ({
  root: {
    width: '100%',
  },
  expansion: {
    background: 'inherit',
  },
  expansionSummaryRoot: {
    '& .MuiIconButton-root': {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
      },
    },
  },
  expansionSummaryContent: {
    marginTop: 0,
    marginBottom: 0,
    transition: 'none',
    '&$expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  titleAssemblyContainer: {
    marginBottom: 24,
    marginTop: 16,
  },
  selected: {
    color: theme.customPalette.primaryGreenG02,
  },
  unselected: {
    color: theme.customPalette.neutralGr02,
    [theme.breakpoints.down('sm')]: {
      color: '#999999',
    },
  },
  selectedArrow: {
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  rotateUp: {
    '-webkit-transform': 'rotate(-90deg)',
    '-ms-transform': 'rotate(-90deg)',
    transform: 'rotate(-90deg)',
  },
  rotateDown: {
    '-webkit-transform': 'rotate(90deg)',
    '-ms-transform': 'rotate(90deg)',
    transform: 'rotate(90deg)',
  },
  imageOverride: {
    [theme.breakpoints.down('sm')]: {
      width: '24px!important',
      height: '24px!important',
    },
  },
  unselectedArrow: {
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  arrowSliderContainer: {
    cursor: 'pointer',
    paddingTop: 14,
    paddingBottom: 14,
    borderBottom: `1px solid ${theme.customPalette.neutralGr04}`,
    [theme.breakpoints.down('sm')]: {
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hideOnMobile: {
    display: 'initial',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  expanded: {},
  details: {
    borderTop: `1px solid ${theme.customPalette.neutralGr01}`,
    padding: 0,
    flexDirection: 'column',
  },
  subscriptionLinkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: '18px!important',
      paddingBottom: '18px!important',
    },
  },
  statusDetailsWrapper: {},
  spacingHeader: {
    '& .MuiGrid-item': {
      padding: theme.spacing(2, 1, 1, 1),
    },
  },
  spacingBody: {
    '&>.MuiGrid-item': {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '&>.MuiGrid-item': {
        padding: theme.spacing(2),
      },
    },
  },
  headerVerticalDivider: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr01}`,
    },
  },
  headerVerticalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr01}`,
    },
  },
  headerHorizontalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.customPalette.neutralGr01}`,
    },
  },
  bodyVerticalDivider: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  bodyVerticalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  bodyHorizontalDivider: {
    [theme.breakpoints.up('md')]: {
      borderTop: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  bodyHorizontalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  assemblyBody: {
    [theme.breakpoints.down('sm')]: {},
  },
  trackingUrl: {
    '& p': {
      color: theme.customPalette.secondaryYellowYButtons,
      fontSize: '10px',
      fontWeight: 'bold',
      border: 'none',
      textTransform: 'capitalize',
    },
  },
  gift: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '0!important',
    },
  },
  giftContainer: {
    padding: '24px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0 0 0',
    },
  },
  giftTitle: {
    [theme.breakpoints.down('md')]: {
      padding: '0 16px 0 16px',
    },
  },
  progressWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  flexLeftCard: {
    display: 'flex',
    height: 365,
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 'unset',
    },
  },
});

export const packagePackage = (theme) => ({
  root: {
    width: '100%',
    marginBottom: '0px !important',
    fontFamily: 'Poppins',
  },
  modelAccordionSummary: {
    position: 'absolute',
    top: '100%',
    left: 0,
    minHeight: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    border: '1px solid' + theme.customPalette.neutralGr03,
    borderTop: 'none',
    width: '100%',
  },
  sectionTitle: {
    margin: theme.spacing(6, 0, 5, 0),
    fontFamily: 'Poppins',
  },
  wrapper: {
    display: 'flex',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  accordionStyle: {},
  accordionSummary: {
    fontFamily: 'Poppins',
    border: '0.5px solid' + theme.customPalette.neutralGr04,
  },
  accordionDetails: {
    border: '0.5px solid' + theme.customPalette.neutralGr04,
    borderTop: 'none',
    padding: '0',
  },
  buttonCustom: {
    backgroundColor: 'transparent',
    border: '1px solid' + theme.customPalette.secondaryYellowYButtons,
    height: '40px',
    maxWidth: '225px',
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
    '& :hover': {
      color: '#fff !important',
    },
  },
  warningNotPaid: {
    backgroundColor: theme.customPalette.secondaryPinkP01,
    padding: '16px',
    color: '#fff',
    '& span': {
      paddingLeft: '16px',
    },
  },
  giftContainer: {
    backgroundColor: theme.customPalette.neutralGr02,
    padding: '24px',
  },
  orderSequence: {
    fontSize: '20px',
    fontWeight: 500,
  },
  mainTitle: {
    color: theme.customPalette.primaryGreenG02,
    fontSize: '20px',
    fontWeight: 600,
  },
  tangerineUnderline: {
    margin: '28px 0',
    cursor: 'pointer',
  },

  iconDownload: {
    width: '22px',
    height: '22px',
  },
  invoiceText: {
    color: theme.customPalette.secondaryYellowYButtons,
  },
  noPadding: {
    padding: '0!important',
  },
  expansion: {
    background: 'inherit',
  },
  fieldTitle: {
    color: theme.customPalette.neutralGr04,
  },
  expansionSummaryRoot: {
    // "& .MuiIconButton-root": {
    //     [theme.breakpoints.down("sm")]: {
    //         display: "block",
    //         position: "absolute",
    //         right: theme.spacing(1),
    //         top: theme.spacing(1),
    //     },
    // },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflowX: 'scroll',
  },
  expansionSummaryContent: {
    marginTop: 0,
    marginBottom: 0,
    transition: 'none',
    '&$expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  expanded: {},
  details: {
    borderTop: `1px solid ${theme.customPalette.neutralGr01}`,
    padding: 0,
    flexDirection: 'column',
  },
  subscriptionLinkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  statusDetailsWrapper: {},
  spacingHeader: {
    '& .MuiGrid-item': {
      padding: theme.spacing(2, 1, 1, 1),
    },
  },
  spacingBody: {
    '&>.MuiGrid-item': {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '&>.MuiGrid-item': {
        padding: theme.spacing(1),
      },
    },
  },
  headerVerticalDivider: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr01}`,
    },
  },
  headerVerticalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr01}`,
    },
  },
  headerHorizontalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.customPalette.neutralGr01}`,
    },
  },
  bodyVerticalDivider: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  bodyVerticalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderLeft: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  bodyHorizontalDivider: {
    [theme.breakpoints.up('md')]: {
      borderTop: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  bodyHorizontalDividerMobile: {
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${theme.customPalette.neutralGr02}`,
    },
  },
  trackingUrl: {
    '& p': {
      color: theme.customPalette.secondaryYellowYButtons,
      fontSize: '10px',
      fontWeight: 'bold',
      border: 'none',
      textTransform: 'capitalize',
    },
  },
  orderHeader: {
    backgroundColor: theme.customPalette.neutralGr01,
    paddingTop: '10px',
    paddingBottom: '10px',
    overflowX: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  },
  progressWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  paymentStatusWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  paymentAction: {
    maxWidth: '180px',
    width: '100%',
  },
  placeholder: {
    padding: '0px!important',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  totalPopUpContainer: {},
  tooltip: {
    minWidth: 259,
    padding: '16px 12px',
    boxShadow: 'none',
    backgroundColor: 'white',
    border: `1px solid ${theme.customPalette.secondaryPinkP01}`,
    borderRadius: '12px',
    marginTop: '14px',
  },
  tooltipArrow: {
    color: theme.customPalette.secondaryPinkP01,
  },
  icon: {
    height: 16,
    width: 16,
  },
  iconContainer: {
    marginLeft: 10,
    height: 16,
    width: 16,
  },
  dialogContent: {
    margin: 16,
  },
  borders: {
    border: `1px solid ${theme.customPalette.secondaryPinkP01}`,
    borderRadius: '12px',
    padding: '0 16px',
  },
  pointer: {
    cursor: 'pointer',
  },
  itemTooltipList: {
    margin: '4px 0',
    color: theme.customPalette.neutralGy05,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: '16px 0',
    },
  },
  orderInfoLabel: {
    fontSize: '14px',
    fontWeight: '500',
  },
  orderInfoValue: {
    fontSize: '14px',
    fontWeight: 'normal',
  },
  listItem: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  price: {
    fontSize: '20px !important',
    fontWeight: '600 !important',
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& .MuiListItemText-primary': {
      fontSize: '14px',
      fontWeight: '500',
      paddingRight: '8px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
      },
    },
    '& .MuiListItemText-secondary': {
      fontSize: '12px',
      fontWeight: 'normal',
      color: theme.customPalette.neutralGy05,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        textAlign: 'right',
      },
    },
  },
});

export const packageStepper = (theme) => ({
  stepper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  stepperContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    padding: '0 10px',
    flexGrow: 2,
    alignItems: 'center',
  },
  stepStatus: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 0,
    },
  },
  stepIcon: {
    width: 24,
    height: 24,
  },
  stepLabel: {
    fontWeight: '600',
    fontSize: '16px !important',
  },
  stepDescription: {
    color: theme.customPalette.neutralGy05,
    fontStyle: 'italic',
  },
  stepArrow: {
    cursor: 'pointer',
    fontSize: '13px',
    whiteSpace: 'nowrap',
    '&-prev::before': {
      content: '"< "',
    },
    '&-next::after': {
      content: '" >"',
    },
  },
  labelActive: {
    color: theme.customPalette.neutralGy05,
  },
});

export const packageStatusDetails = (theme) => ({
  fieldTitle: {
    color: theme.customPalette.neutralGr04,
  },
  text: {
    marginTop: 8,
  },
});

export const packageFullKit = (theme) => ({
  fullKitContainer: {
    [theme.breakpoints.up('sm')]: {},
  },
  stepperBg: {
    backgroundColor: theme.customPalette.neutralGr01,
    padding: '16px 8px',
  },
  accordionSummary: {
    backgroundColor: theme.customPalette.neutralGr02,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  summaryTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    color: theme.customPalette.neutralGy05,
  },
  accordionDetails: {
    backgroundColor: '#fff',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 10),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 15),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(5, 20),
    },
  },
  trackingUrl: {
    marginTop: '5px',
    justifyContent: 'start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end',
    },
    '& p': {
      color: theme.customPalette.secondaryYellowYButtons,
      fontSize: '10px',
      fontWeight: 'bold',
      border: 'none',
      textTransform: 'capitalize',
    },
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& .MuiListItemText-primary': {
      color: theme.customPalette.neutralGy05,
      fontSize: 14,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      flexBasis: '100%',
      wordBreak: 'break-word',
    },
    '& .MuiListItemText-secondary': {
      color: theme.customPalette.neutralGy05,
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Poppins',
      flexBasis: '100%',
      wordBreak: 'break-word',
      '& b': {
        textTransform: 'capitalize',
      },
    },
  },
  imgContainer: {
    width: '100%',
    // maxHeight: 400,
    maxHeight: '100%',
    height: '100%',
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
      objectPosition: 'center',
    },
  },
  giftMainTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.customPalette.primaryGreenG02,
    fontFamily: 'Poppins',
    margin: 0,
  },
  giftDesc: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.customPalette.neutralGy05,
    fontFamily: 'Poppins',
    marginLeft: 8,
  },
  giftImg: {
    maxHeight: 215,
    // height: "100%",
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  giftImgSmall: {
    // height: "100%",
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
  },
  giftBtn: {
    backgroundColor: theme.customPalette.secondaryYellowYButtons,
    color: '#fff',
    maxWidth: 280,
    width: '100%',
    height: 45,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px',
    '& span': {
      paddingLeft: '4px',
      lineHeight: 1,
    },
  },
  giftBox: {
    backgroundColor: theme.customPalette.neutralGr02,
  },
  invoiceText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginBottom: '-5px',
    paddingLeft: 16,
  },
  buttonCustom: {
    height: '40px',
    maxWidth: '225px',
    width: '100%',
    lineHeight: 1.5,
  },
  iconDownload: {
    width: '22px',
    height: '22px',
    filter: 'brightness(0) invert(1)',
  },
  bgGift: {
    backgroundColor: theme.customPalette.neutralGr02,
  },
  bgLayout: {
    display: 'flex',
    // flexDirection: "column",
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      // flexDirection: "row",
      alignItems: 'flex-start',
    },
  },
  bgLayoutWithDesc: {
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  bgLayoutWithNoDesc: {
    flexDirection: 'column',
  },
});

export const packageRegular = (theme) => ({
  border: '10px solid #336699',
  accordionSummary: {
    border: '.5px solid' + theme.customPalette.neutralGr04,
    backgroundColor: '#fff',

    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(0deg) !important',
    },
  },
  statusPO: {
    backgroundColor: '#fff',
    color: theme.customPalette.primaryGreenG02,
    borderColor: theme.customPalette.primaryGreenG02,
  },
  // packageContent: {
  //     paddingTop: "16px",
  //     paddingBottom: "16px",
  // },
  paymentAction: {
    maxWidth: '180px',
    width: '100%',
    '& span': {
      fontSize: 16,
      fontFamily: 'Poppins',
      fontWeight: 'bold',
      marginBottom: '-5px',
    },
  },
  accordion: {
    marginTop: 8,
    marginBottom: 'calc(16 + 57)px',
    paddingBottom: 0,
  },
  accordionDetails: {
    border: '.5px solid' + theme.customPalette.neutralGr04,
    borderTop: 'none',
    backgroundColor: '#fffff',
    padding: 0,
  },
  orderSequence: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.customPalette.neutralGy05,
  },
  modelAccordionSummary: {
    position: 'absolute',
    top: '100%',
    left: 0,
    minHeight: '48px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    border: '1px solid' + theme.customPalette.neutralGr03,
    borderTop: 'none',
    width: '100%',
    // overflowX: "scroll",
    wordBreak: 'break-word',
    fontSize: '.8rem',
    '& span': {
      fontSize: 14,
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      flexDirection: 'column',
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    '& b': {
      fontSize: 14,
      fontFamily: 'Poppins',
      fontWeight: '500',
    },
  },
  trackingUrl: {
    justifyContent: 'start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end',
    },
    '& p': {
      color: theme.customPalette.primaryGreenG02,
      fontSize: '12px',
      border: 'none',
      textDecoration: 'underline',
      textTransform: 'capitalize',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
    },
  },
  warningNotPaid: {
    backgroundColor: theme.customPalette.secondaryPinkP01,
    padding: '16px',
    color: '#fff',
    '& span': {
      paddingLeft: '16px',
      fontSize: 14,
      fontWeight: 'normal',
      fontFamily: 'Poppins',
      lineHeight: 1.14,
      [theme.breakpoints.up('sm')]: {},
    },
  },
  stepperContainer: {
    overflowX: 'hidden',
    backgroundColor: theme.customPalette.neutralGr02,
    flexDirection: 'column',
    paddingBottom: 8,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      paddingBottom: 0,
    },
    '& span': {
      fontSize: '14px',
    },
    '& ::-webkit-scrollbar': {
      display: 'none',
    },
    '&:hover': {
      overflowX: 'auto',
    },
  },
  listItem: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  price: {
    fontSize: '20px !important',
    fontWeight: '600 !important',
    marginBottom: '-5px',
  },
  listItemText: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontFamily: 'Poppins',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: '5px',
    },

    '& .MuiListItemText-primary': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '500',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '12px',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      color: theme.customPalette.neutralGy05,
      display: 'flex',
      textAlign: 'left',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        textAlign: 'right',
      },
    },
  },
  deliveryItemText: {
    display: 'flex',
    alignItems: 'start',
    gap: '5px',
    wordBreak: 'keep-all',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      alignItems: 'end',
    },
  },
  deliveryItemTextExpanded: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '5px',
    wordBreak: 'keep-all',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
      alignItems: 'end',
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  totalPopUpContainer: {},
  tooltip: {
    minWidth: 259,
    padding: '16px 12px',
    boxShadow: 'none',
    backgroundColor: 'white',
    border: `1px solid ${theme.customPalette.secondaryPinkP01}`,
    borderRadius: '12px',
    marginTop: '14px',
  },
  tooltipArrow: {
    color: theme.customPalette.secondaryPinkP01,
  },
  icon: {
    height: 16,
    width: 16,
    cursor: 'pointer',
    filter: 'grayscale(1)',
  },
  iconContainer: {
    marginLeft: 10,
    height: 16,
    width: 16,
  },
  dialogContent: {
    margin: 16,
  },
  invoiceText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Poppins',
    marginBottom: '-5px',
    paddingLeft: 16,
  },
  buttonCustom: {
    height: '40px',
    maxWidth: '225px',
    width: '100%',
    lineHeight: 1.5,
  },
  iconDownload: {
    width: '22px',
    height: '22px',
    filter: 'brightness(0) invert(1)',
  },
  mainTitle: {
    color: theme.customPalette.primaryGreenG02,
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
  boxStepper: {
    paddingBottom: '0',

    [theme.breakpoints.up('sm')]: {
      paddingBottom: 16,
    },
  },
  missingPackage: {
    '&-left': {
      padding: '20px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: '20px 20px 0',
      },
    },
    '&-right': {
      padding: '20px',
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        padding: '0 20px 20px',
      },
    },
  },
  mainLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: '8px',
  },
  nextShipmentBanner: {
    width: '100%',
    backgroundColor: theme.customPalette.neutralGr02,
    '& > h3': {
      fontSize: '20px',
      fontWeight: 600,
      margin: '0 0 5px',
    },
    '& > p': {
      fontSize: '18px',
      fontWeight: 300,
      margin: 0,
    },
  },
});
