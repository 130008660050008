// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { useStores } from '../stores';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Input from './common/Input';
import Button from './common/Button';
import appStyles from '../../../config/appStyles.js';
import { useDropzone } from 'react-dropzone';
import Image from './common/Image';

const useStyles = makeStyles((theme) => appStyles.contactForm(theme));

const ContactForm = observer(
  ({
    id,
    topicsObj,
    productCode,
    topicName,
    subtopicName,
  }) => {
    const { t } = useTranslation(['common', 'components', 'validations']);
    const classes = useStyles();
    const { uiStore, helpStore, orderStore } = useStores();
    const { orders, currentOrder } = orderStore;
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [topic, setTopic] = useState();
    const [subs, setSubs] = useState([]);
    const [subsOptions, setSubsOptions] = useState([]);
    const [subtopic, setSubtopic] = useState('');
    const [subtopicOptions, setSubtopicOptions] = useState();

    const mapToOption = (s) => ({
      id: s.code,
      label: s.short || s.name,
      value: s.name,
    });

    // Upload section
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
      useDropzone({
        accept: 'image/jpeg, image/png, .pdf, .doc, .kpeg',
        maxSize: 10000000,
        maxFiles: 1,
        onDropRejected: function () {
          alert(
            'Unsupported size (image/jpeg, image/png, .pdf, .doc, .kpeg) or size too large (10Mb)',
          );
        },
      });
    const acceptedFileItems = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));

    const resetForm = useCallback(() => {
      setMessage('');
      setEmail('');
      const tempArray = (orders || []).map(mapToOption);
      const subsOptions = tempArray.filter(
        (el, i) => i === tempArray.findIndex((e) => e.id === el.id),
      );
      const defaultSub = subsOptions.filter((o) => o.id === currentOrder.id);
      setMessage('');
      setTopic(topicsObj && topicsObj.length > 0 ? topicsObj[0] : '');
      const stvalues =
        topicsObj && topicsObj.length > 0 && topicsObj[0].subTopics
          ? topicsObj[0].subTopics
          : [];
      setSubtopicOptions(stvalues);
      setSubtopic(stvalues && stvalues.length > 0 ? stvalues[0] : '');

      setSubsOptions(subsOptions);
      setSubs(defaultSub);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrder, orders]);

    const handleChangeMessage = (e) => {
      setMessage(e.target.value);
    };

    const handleChangeEmail = (e) => {
      setEmail(e.target.value);
    };

    const handleChangeName = (e) => {
      setName(e.target.value);
    };
    const handleChangeSurname = (e) => {
      setLastName(e.target.value);
    };

    useEffect(() => {
      resetForm();
    }, [resetForm]);

    const handleSubmit = () => {
      uiStore.setLoader(true);
      let fd = new FormData();

      acceptedFiles.forEach((file) => {
        fd.append('File[]', file);
      });
      const index = topicsObj.findIndex(item => item.topic === topicName);
      let paramsContact = {
        message: message,
        email: email,
        site: uiStore.siteId,
        name: name,
        surname: lastName,
        subtopic: subtopicName || '',
        products: productCode || [],
        topic: topicsObj[index] || ' ',
      };

      if (acceptedFiles) {
        let file = acceptedFiles[0];
        paramsContact.attachment = file;
      }

      if (fd.length > 0) {
        paramsContact.attachment = fd;
      }

      helpStore
        .createOpenTicket(paramsContact)
        .then((res) => {
          if (res) {
            uiStore.openModal(
              t('components:ContactForm.thankYouMessage'),
              false,
            );
            resetForm();
          } else {
            uiStore.showGenericError();
          }
          uiStore.setLoader(false);
        })
        .catch((err) => {
          console.log('err++', err);
          uiStore.showGenericError();
          uiStore.setLoader(false);
        });
    };

    return (
      <Box className={classes.contactForm}>
        <div id={id + '-questions'}>
          <Grid container className={classes.container}>
            <Grid item xs={12} md={12}>
              <Box display="flex" flexDirection="column">
                <span className={classes.mainTitle}>
                  {t('components:ContactForm.notSolvedDoubt')}
                </span>
                {!window.location.href.includes('fanhome') &&
                  uiStore.site !== 'cz' &&
                  uiStore.site !== 'sk' && (
                    <span className={classes.upTitle}>
                      {t('components:ContactForm.upTitle')}
                    </span>
                  )}
              </Box>
            </Grid>


            <Grid item xs={12} md={12}>
              <ValidatorForm
                id="subscribe-form"
                style={{ width: '100%' }}
                onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {/* <Grid xs={12} md={4} item>
                                      <Select
                                          className={classes.topicSelect}
                                          name="topic"
                                          value={topic}
                                          validators={["required"]}
                                          errorMessages={[
                                              t("validations:requiredField", {
                                                  name: t(
                                                      "components:ProfileInfo.name",
                                                  ),
                                              }),
                                          ]}
                                          onChange={handleChangeTopic}
                                      >
                                          {topics.map((topic) => (
                                              <MenuItem
                                                  key={`topic_${topic}`}
                                                  value={topic}
                                              >
                                                  {topic}
                                              </MenuItem>
                                          ))}
                                      </Select>
                                  </Grid>
                                  <Grid xs={12} md={4} item>
                                      <Autocomplete
                                          className={classes.subscriptionSelect}
                                          name="subscriptions"
                                          placeholder={t(
                                              "components:ContactForm.selectSubsPlaceholder",
                                          )}
                                          value={subs}
                                          options={subsOptions}
                                          onChange={handleChangeSubs}
                                      />
                                  </Grid>
                                 */}

                  {/* Email */}
                  <Grid xs={12} md={6} item>
                    <Input
                      className={classes.messageInput}
                      name={'email'}
                      placeholder={t('components:ContactForm.emailPlaceholder')}
                      label={
                        <span>
                          {t('components:ContactForm.emailPlaceholder').replace(
                            /\*/,
                            '',
                          )}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      value={email}
                      validators={['required']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:ProfileInfo.email'),
                        }),
                      ]}
                      onChange={handleChangeEmail}></Input>
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <Input
                      className={[classes.messageInput, classes.w100]}
                      name={'name'}
                      placeholder={t('components:PublicContactForm.name')}
                      label={
                        <span>
                          {t('components:PublicContactForm.name').replace(
                            /\*/,
                            '',
                          )}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      value={name}
                      validators={['required']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:PublicContactForm.name'),
                        }),
                      ]}
                      onChange={handleChangeName}></Input>
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <Input
                      className={[classes.messageInput, classes.w100]}
                      label={
                        <span>
                          {t('components:PublicContactForm.lastName').replace(
                            /\*/,
                            '',
                          )}{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </span>
                      }
                      name={'lastName'}
                      placeholder={t('components:PublicContactForm.lastName')}
                      value={lastName}
                      validators={['required']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:PublicContactForm.lastName'),
                        }),
                      ]}
                      onChange={handleChangeSurname}></Input>
                  </Grid>

                  {/* Message */}
                  <Grid xs={12} item>
                    <Input
                      className={classes.messageInput}
                      name={'message'}
                      placeholder={t(
                        'components:ContactForm.messagePlaceholder',
                      )}
                      label={
                        <span>
                          {t(
                            'components:ContactForm.messagePlaceholder',
                          ).replace(/\*/, '')}{' '}
                        </span>
                      }
                      value={message}
                      validators={['required']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:ProfileInfo.name'),
                        }),
                      ]}
                      multiline
                      rows={10}
                      onChange={handleChangeMessage}></Input>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* Upload */}
                    <div className="col-24">
                      <Box
                        px={2}
                        py={2}
                        mt={2}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        className="bg-grey02"
                        width="100%">
                        <span
                          className={[
                            'd-block mb-2 upload-file-title  text-grey05',
                            classes.uploadTitle,
                          ]}>
                          {t('components:ContactForm.uploadFileTitle')}
                        </span>

                        <Box
                          mb={2}
                          {...getRootProps({
                            className: classes.dropzone,
                          })}>
                          <input {...getInputProps()} />
                          <Box
                            mb={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            width="100%"
                            className={[
                              classes.dragInput,
                              isDragActive
                                ? classes.dragInputActive
                                : classes.dragInputDeactive,
                            ]}>
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="center"
                              p={2}>
                              <Image
                                name="download"
                                className={classes.icon}></Image>
                              <span className={classes.dropzoneUploadBtnTitle}>
                                {t('components:ContactForm.boxUpload')}
                              </span>
                            </Box>
                          </Box>
                        </Box>

                        <Box mb={2} className={classes.fileAccepted}>
                          {t('components:ContactForm.fileAccepted')}
                        </Box>

                        <div className="">
                          <aside>
                            <ul>{acceptedFileItems}</ul>
                            {/* <h4>Rejected files</h4>
                        <ul>{fileRejectionItems}</ul> */}
                          </aside>
                        </div>
                      </Box>
                    </div>
                  </Grid>
                  <Grid xs={12} item>
                    <Button
                      type="submit"
                      className={classes.submitButton}
                      size="large">
                      {t('components:ContactForm.sendButton')}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
          </Grid>
        </div>
      </Box>
    );
  },
);

export default ContactForm;
