import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, MenuItem, Container, Box, Hidden } from "@material-ui/core";
import { useStores } from "../stores";
import Progress from "../components/common/Progress";
import VideoItemGallery from "../components/VideoItemGallery";
import appStyles from "../../../config/appStyles.js";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Text from "../components/common/Text";

import VideoIssuesContainer from "./../components/VideoIssuesContainer";
import { ValidatorForm } from "react-material-ui-form-validator";
import Select from "../components/common/Select";
import GiftItemVideo from "../components/GiftItemVideo";
import Service from "../components/Service";
import Video from "../components/common/Video";
// Import Swiper styles
import "swiper/swiper.scss";
import Button from "../components/common/Button";
import Icon from "../components/common/Icon";
import { toJS } from "mobx";
import ButtonMarked from "../components/ButtonMarked";
import Image from "./../components/common/Image";
import SubscribeForm from "./../components/SubscribeForm";
import { sitesId } from "../../../stores/Constants";

// install Swiper modules
SwiperCore.use([Navigation]);

const swiperResponsive = {
  768: {
    slidesPerView: 3,
    spaceBetween: 8,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 16,
  },
  1440: {
    slidesPerView: 6,
    spaceBetween: 16,
  },
};

const useStyles = makeStyles((theme) => appStyles.tutorials(theme));

const Tutorials = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "components"]);
  const { orderStore, collectionStore, userStore, uiStore } = useStores();
  const [indexPackage, setIndexPackage] = useState(0);
  const [details, setDetails] = useState([]);
  const [detailsClosed, setDetailsClosed] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shipmentFK, setShipmentsFK] = useState();
  // Swiper buttons
  const [nextEl, setNextEl] = useState("nextIcon");
  const [prevEl, setPrevEl] = useState("prevIcon");
  const [realIndexActive, setRealIndexActive] = useState(0);
  const [indexReachEnd, setIndexReachEnd] = useState(0);

  const { nextShipment, currentOrder, shipments, isLoadingShipments } =
    orderStore;
  const { user, hasSubscriptions, subscriptions } = userStore;
  const { site } = uiStore;
  const homePage = t("components:Footer.homePage", {
    returnObjects: true,
    defaultValue: {},
  });

  const { groupedIssues, grouped } = collectionStore;

  useEffect(() => {
    if (shipments && shipments.open && shipments.open.length > 0) {
      const pack = shipments.open[0];
      orderStore.setCurrentShipmentDetails([]);
      orderStore
        .getShipmentDetails(pack)
        .then((details) => {
          orderStore.setCurrentShipmentDetails(details);
          setDetails(details);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (shipments && currentOrder.variantAttributes.plan === "FK") {
      if (shipments.closed && shipments.closed.length > 0) {
        setShipmentsFK(shipments.closed);
      }
      if (shipments.open && shipments.open.length > 0) {
        setShipmentsFK(shipments.open);
      }

      if (shipmentFK) {
        const pack = shipmentFK[shipmentFK.length - 1];
        orderStore.setCurrentShipmentDetails([]);
        orderStore
          .getShipmentDetails(pack)
          .then((details) => {
            orderStore.setCurrentShipmentDetails(details);
            setDetailsClosed(details);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [shipments]);

  useEffect(() => {
    if (user.email && user.UID) {
      userStore.fetchSubscriptions(user.email, user.UID);
    }
  }, [user.UID, user.email, userStore]);

  const opts = groupedIssues && groupedIssues.sort((a, b) => b.packageNumber - a.packageNumber)
  const optsGrouped = grouped && grouped.sort((a, b) => b.shipment - a.shipment)
  const otherMenuOpts = opts ?
    opts.map((el, index) => {
      return {
        label: t("components:AssemblyTutorials.selectLabel", {
          number: opts[index].packageNumber,
          from: opts[index].firstIssue,
          to: opts[index].lastIssue,
        }),
        value: opts[index].packageNumber,
      };
    })
    : [];

  useEffect(() => {
    orderStore.fetchShipments();
    orderStore.fetchNextShipments();
    orderStore.fetchNextGift();
    collectionStore.fetchCollection(currentOrder);
  }, [currentOrder]);

  useEffect(() => {
    const ns = toJS(nextShipment);
    if (
      (ns && typeof ns.package !== "undefined" && !ns.package) ||
      ns === false ||
      Object.keys(ns).length === 0
    ) {
      orderStore.fetchShipments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, nextShipment]);

  const changeIndexPackage = (e) => {
    setIndexPackage(e.target.value);
  };
  const completedClick = (issue) => {
    const item = issue;
    setLoading(true);
    collectionStore.markComplete(() => setLoading(false), item);
  };

  return (
    <React.Fragment>
      {isLoadingShipments ? (
        <Progress></Progress>
      ) : (
        <div>
          {shipments && shipments.closed && shipments.closed.length > 0 ? (
            <Fragment>
              <Container maxWidth="xxl">
                {nextShipment &&
                  nextShipment &&
                  !shipments &&
                  !shipments.open &&
                  shipments.open.length === 0 && (
                    <>
                      {console.log("++++++++++ AssemblyTutorials")}
                      <Grid item xs={12} md={12}>
                        <h2 className={classes.mainTitle}>
                          {t("components:AssemblyTutorials.title")}
                        </h2>
                      </Grid>

                      <VideoItemGallery
                        open={true}
                        pack={nextShipment}
                      ></VideoItemGallery>
                    </>
                  )}
                {shipments &&
                  shipments.open &&
                  shipments.open.length > 0 &&
                  currentOrder &&
                  currentOrder.variantAttributes.plan !== "FK" && (
                    <>
                      <Grid item xs={12} md={12}>
                        <h2 className={classes.mainTitle}>
                          {t("components:VideoTutorial.sectionTitle")}
                        </h2>
                      </Grid>
                      <Hidden smDown>
                        <Grid container spacing={2}>
                          <VideoItemGallery
                            open={true}
                            pack={shipments.open[0]}
                          ></VideoItemGallery>
                        </Grid>
                      </Hidden>
                      <Hidden mdUp>
                        <Grid item xs={12} md={12}>
                          <Swiper
                            slidesPerView={1.1}
                            spaceBetween={8}
                            navigation={{
                              nextEl: `#${nextEl}`,
                              prevEl: `#${prevEl}`,
                            }}
                            onSlideChange={(swiperCore) => {
                              const { realIndex, isEnd } = swiperCore;
                              setRealIndexActive(realIndex);
                              setIndexReachEnd(isEnd ? true : false);
                            }}
                          >
                            {shipments &&
                              shipments.open &&
                              shipments.open.length > 0 &&
                              details &&
                              details.issues ? (
                              details.issues.map((issue, index) => {
                                if (issue?.video && issue?.video?.source) {
                                  return (
                                    <SwiperSlide>
                                      <Video
                                        className={[classes.videoClass]}
                                        fullscreen={false}
                                        badge={
                                          issue.video.label ||
                                          "Placeholder title"
                                        }
                                        url={issue?.video?.source}
                                        preview={
                                          issue.video.cover &&
                                            issue.video.cover.src
                                            ? {
                                              src: issue.video.cover.src,
                                            }
                                            : "https://via.placeholder.com/250x250?text=no+video+available"
                                        }
                                      ></Video>
                                    </SwiperSlide>
                                  );
                                }
                              })
                            ) : (
                              <Progress variant="medium"></Progress>
                            )}
                          </Swiper>
                        </Grid>
                        <Grid>
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            className={classes.swiperButtons}
                          >
                            <button
                              id="prevIcon"
                              className={classes.prevIcon}
                              style={{
                                opacity: realIndexActive === 0 ? "0" : "1",
                              }}
                            >
                              <Image name="circle-arrow-right" />
                            </button>
                            <button
                              id="nextIcon"
                              style={{ opacity: indexReachEnd ? "0" : "1" }}
                            >
                              <Image name="circle-arrow-right" />
                            </button>
                          </Box>
                        </Grid>
                      </Hidden>
                    </>
                  )}
              </Container>

              {/* Download instrucions for 12M */}
              <Container>
                {/* 12 plan - download magazine */}
                {currentOrder &&
                  currentOrder.variantAttributes.plan === "12" && (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.instructionsContainer}
                    >
                      {/* Download the istructions */}
                      {details &&
                        details.issues &&
                        details.issues.map((issue, index) => {
                          if (index === 0) {
                            return (
                              <Grid xs={12} md={12}>
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Grid xs={2} md={2}>
                                    <Box
                                      px={1}
                                      className={classes.instructionsGuide}
                                    >
                                      <img
                                        src={issue?.image?.src}
                                        alt={issue.image.alt}
                                        title={issue?.image?.title}
                                      ></img>
                                    </Box>
                                  </Grid>
                                  <Grid xs={10} md={10}>
                                    <Box px={1}>
                                      <h3 className={classes.instructionsTitle}>
                                        {t(
                                          "components:VideoTutorial.downloadTitle"
                                        )}
                                      </h3>
                                      <p className={classes.instructionsDesc}>
                                        {t(
                                          "components:VideoTutorial.downloadDesc"
                                        )}
                                      </p>

                                      {window.location.href.includes(
                                        "deagostini"
                                      )
                                        ? (currentOrder?.variantAttributes
                                          ?.plan === "FK" ||
                                          currentOrder?.variantAttributes
                                            ?.plan === "12" ||
                                          currentOrder?.offerType ===
                                          "PREMIUM") &&
                                        issue.magazine && (
                                          <a
                                            style={{
                                              textDecoration: "none",
                                            }}
                                            href={issue?.magazine?.src || ""}
                                            title={issue?.magazine?.title}
                                            alt={issue?.magazine?.alt}
                                          >
                                            <Button
                                              className={
                                                classes.instructionsBtn
                                              }
                                            >
                                              <Icon name="download_tangerine"></Icon>
                                              <span>
                                                {t(
                                                  "components:VideoTutorial.downloadCTA"
                                                )}
                                              </span>
                                            </Button>
                                          </a>
                                        )
                                        : window.location.href.includes(
                                          "fanhome"
                                        ) &&
                                        issue.magazine && (
                                          <a
                                            style={{
                                              textDecoration: "none",
                                            }}
                                            href={issue?.magazine?.src || ""}
                                            title={issue?.magazine?.title}
                                            alt={issue?.magazine?.alt}
                                          >
                                            <Button
                                              className={
                                                classes.instructionsBtn
                                              }
                                            >
                                              <Icon name="download_tangerine"></Icon>
                                              <span>
                                                {t(
                                                  "components:VideoTutorial.downloadCTA"
                                                )}
                                              </span>
                                            </Button>
                                          </a>
                                        )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Grid>
                  )}
              </Container>
              <Container container>
                {/* PACKAGE FK */}
                {currentOrder &&
                  currentOrder.variantAttributes.plan === "FK" && (
                    <Grid
                      container
                      className={[
                        classes.packageVideoContainer,
                        !hasSubscriptions ? classes.spaceBottom : "",
                      ]}
                      justifyContent="center"
                    >
                      {/* Package closed */}
                      {shipmentFK && (
                        <Container
                          container
                          maxWidth={"lg"}
                          className={classes.openVideoTutorialsContainer}
                        >
                          <Grid container justifyContent="center">
                            <Grid item xs={12} md={12}>
                              <h3 className={classes.packageTitle}>
                                {t("components:VideoTutorial.alreadyReceived")}
                              </h3>
                            </Grid>

                            {/* Fullkit plan - download magazine */}
                            {currentOrder &&
                              currentOrder.variantAttributes.plan === "FK" && (
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                  className={classes.instructionsContainer}
                                >
                                  {/* Download the istructions */}
                                  {detailsClosed &&
                                    detailsClosed.issues &&
                                    detailsClosed.issues.map((issue, index) => {
                                      if (index === 0) {
                                        return (
                                          <Fragment>
                                            <Grid xs={12} md={4}>
                                              <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                              >
                                                <Grid xs={4} md={4}>
                                                  <Box
                                                    px={1}
                                                    className={
                                                      classes.instructionsGuide
                                                    }
                                                  >
                                                    <img
                                                      src={issue?.image?.src}
                                                      alt={issue.image.alt}
                                                      title={
                                                        issue?.image?.title
                                                      }
                                                    ></img>
                                                  </Box>
                                                </Grid>
                                                <Grid xs={8} md={8}>
                                                  <Box px={1}>
                                                    <h3
                                                      className={
                                                        classes.instructionsTitle
                                                      }
                                                    >
                                                      {t(
                                                        "components:VideoTutorial.downloadTitle"
                                                      )}
                                                    </h3>
                                                    <p
                                                      className={
                                                        classes.instructionsDesc
                                                      }
                                                    >
                                                      {t(
                                                        "components:VideoTutorial.downloadDesc"
                                                      )}
                                                    </p>

                                                    {window.location.href.includes(
                                                      "deagostini"
                                                    )
                                                      ? (currentOrder
                                                        ?.variantAttributes
                                                        ?.plan === "FK" ||
                                                        currentOrder
                                                          ?.variantAttributes
                                                          ?.plan === "12" ||
                                                        currentOrder?.offerType ===
                                                        "PREMIUM") &&
                                                      issue.magazine && (
                                                        <a
                                                          style={{
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                          href={
                                                            issue?.magazine
                                                              ?.src || ""
                                                          }
                                                          title={
                                                            issue?.magazine
                                                              ?.title
                                                          }
                                                          alt={
                                                            issue?.magazine
                                                              ?.alt
                                                          }
                                                        >
                                                          <Button
                                                            className={
                                                              classes.instructionsBtn
                                                            }
                                                          >
                                                            <Icon name="download_tangerine"></Icon>
                                                            <span>
                                                              {t(
                                                                "components:VideoTutorial.downloadCTA"
                                                              )}
                                                            </span>
                                                          </Button>
                                                        </a>
                                                      )
                                                      : window.location.href.includes(
                                                        "fanhome"
                                                      ) &&
                                                      issue.magazine && (
                                                        <a
                                                          style={{
                                                            textDecoration:
                                                              "none",
                                                          }}
                                                          href={
                                                            issue?.magazine
                                                              ?.src || ""
                                                          }
                                                          title={
                                                            issue?.magazine
                                                              ?.title
                                                          }
                                                          alt={
                                                            issue?.magazine
                                                              ?.alt
                                                          }
                                                        >
                                                          <Button
                                                            className={
                                                              classes.instructionsBtn
                                                            }
                                                          >
                                                            <Icon name="download_tangerine"></Icon>
                                                            <span>
                                                              {t(
                                                                "components:VideoTutorial.downloadCTA"
                                                              )}
                                                            </span>
                                                          </Button>
                                                        </a>
                                                      )}
                                                  </Box>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                            {currentOrder?.variantAttributes?.plan !== 'FK' && (
                                              <Grid xs={12} md={3}>
                                                <Box
                                                  height="100%"
                                                  display="flex"
                                                  flexDirection="column"
                                                  justifyContent="flex-end"
                                                  textAlign="right"
                                                >
                                                  <h3
                                                    className={
                                                      classes.instructionsTitle
                                                    }
                                                  >
                                                    {t(
                                                      "components:VideoTutorial.haveAssembled"
                                                    )}
                                                  </h3>
                                                  <ButtonMarked
                                                    pack={shipmentFK}
                                                  ></ButtonMarked>
                                                </Box>
                                              </Grid>
                                            )}
                                          </Fragment>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                </Grid>
                              )}
                          </Grid>
                        </Container>
                      )}
                    </Grid>
                  )}

                {/* PACKAGE RE */}
                {currentOrder &&
                  (currentOrder.variantAttributes.plan === "RE" ||
                    currentOrder.variantAttributes.plan === "PL" ||
                    currentOrder.variantAttributes.plan === "12") && (
                    <Grid
                      container
                      className={[
                        classes.packageVideoContainer,
                        !hasSubscriptions ? classes.spaceBottom : "",
                      ]}
                      justifyContent="center"
                    >
                      {/* Package closed */}
                      {grouped && (
                        <Container
                          container
                          maxWidth={"lg"}
                          className={classes.openVideoTutorialsContainer}
                        >
                          <Grid container justifyContent="center">
                            <Grid item xs={12} md={12}>
                              <h3 className={classes.packageTitle}>
                                {t("components:VideoTutorial.alreadyReceived")}
                              </h3>
                            </Grid>

                            {/* Filter RE plan */}
                            {currentOrder &&
                              currentOrder.variantAttributes.plan !== "FK" && (
                                <Grid container justifyContent="flex-end">
                                  <Grid xs={12} md={4}>
                                    <ValidatorForm
                                      id="filter"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <Select
                                        className={classes.selectPackages}
                                        name="filterPackages"
                                        value={
                                          grouped.length > 1
                                            ? indexPackage
                                            : "all"
                                        }
                                        onChange={(e) => {
                                          changeIndexPackage(e);
                                        }}
                                      >
                                        <MenuItem value={"all"}>
                                          {t("components:VideoTutorial.seeAll")}
                                        </MenuItem>
                                        {otherMenuOpts
                                          .map((ship, index) => {
                                            return (
                                              <MenuItem value={index}>
                                                {ship.label}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </ValidatorForm>
                                  </Grid>
                                </Grid>
                              )}

                            {/* Package */}
                            {optsGrouped.map((ship, index) => {
                              if (indexPackage === "all") {
                                return (
                                  <Grid container>
                                    <Grid item xs={12} md={12}>
                                      <Box p={1} mt={5}>
                                        <span
                                          className={classes.packageSequence}
                                        >
                                          {t(
                                            "components:AssemblyTutorials.packageTitle",
                                            {
                                              number: ship.shipment,
                                            }
                                          )}
                                        </span>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-start"
                                        flexWrap="wrap"
                                      >
                                        <VideoItemGallery
                                          className={classes.videoBox}
                                          pack={ship}
                                        ></VideoItemGallery>
                                      </Box>
                                    </Grid>

                                    {/* 12 plan - download magazine */}
                                    {currentOrder &&
                                      currentOrder.variantAttributes.plan ===
                                      "12" && (
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          alignItems="center"
                                          className={
                                            classes.instructionsContainer
                                          }
                                        >
                                          {/* Download the istructions */}
                                          {details &&
                                            details.issues &&
                                            details.issues.map(
                                              (issue, index) => {
                                                if (index === 0) {
                                                  return (
                                                    <Fragment>
                                                      <Grid xs={12} md={4}>
                                                        <Grid
                                                          container
                                                          spacing={1}
                                                          alignItems="center"
                                                        >
                                                          <Grid xs={4} md={4}>
                                                            <Box
                                                              px={1}
                                                              className={
                                                                classes.instructionsGuide
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  issue?.image
                                                                    ?.src
                                                                }
                                                                alt={
                                                                  issue.image
                                                                    .alt
                                                                }
                                                                title={
                                                                  issue?.image
                                                                    ?.title
                                                                }
                                                              ></img>
                                                            </Box>
                                                          </Grid>
                                                          <Grid xs={8} md={8}>
                                                            <Box px={1}>
                                                              <h3
                                                                className={
                                                                  classes.instructionsTitle
                                                                }
                                                              >
                                                                {t(
                                                                  "components:VideoTutorial.downloadTitle"
                                                                )}
                                                              </h3>
                                                              <p
                                                                className={
                                                                  classes.instructionsDesc
                                                                }
                                                              >
                                                                {t(
                                                                  "components:VideoTutorial.downloadDesc"
                                                                )}
                                                              </p>

                                                              {(currentOrder
                                                                ?.variantAttributes
                                                                ?.plan ===
                                                                "FK" ||
                                                                currentOrder
                                                                  ?.variantAttributes
                                                                  ?.plan ===
                                                                "12" ||
                                                                currentOrder?.offerType ===
                                                                "PREMIUM") &&
                                                                issue.magazine && (
                                                                  <a
                                                                    style={{
                                                                      textDecoration:
                                                                        "none",
                                                                    }}
                                                                    href={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.src ||
                                                                      ""
                                                                    }
                                                                    title={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.title
                                                                    }
                                                                    alt={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.alt
                                                                    }
                                                                  >
                                                                    <Button
                                                                      className={
                                                                        classes.instructionsBtn
                                                                      }
                                                                    >
                                                                      <Icon name="download_tangerine"></Icon>
                                                                      <span>
                                                                        {t(
                                                                          "components:VideoTutorial.downloadCTA"
                                                                        )}
                                                                      </span>
                                                                    </Button>
                                                                  </a>
                                                                )}
                                                            </Box>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                      {currentOrder?.variantAttributes?.plan !== 'FK' && (
                                                        <Grid xs={12} md={3}>
                                                          <Box
                                                            height="100%"
                                                            display="flex"
                                                            flexDirection="column"
                                                            justifyContent="flex-end"
                                                            textAlign="right"
                                                          >
                                                            <h3
                                                              className={
                                                                classes.instructionsTitle
                                                              }
                                                            >
                                                              {t(
                                                                "components:VideoTutorial.haveAssembled"
                                                              )}
                                                            </h3>

                                                            <Button
                                                              onClick={() =>
                                                                completedClick(
                                                                  issue
                                                                )()
                                                              }
                                                            >
                                                              {t(
                                                                "components:VideoTutorial.mark"
                                                              )}
                                                            </Button>
                                                          </Box>
                                                        </Grid>
                                                      )}
                                                    </Fragment>
                                                  );
                                                }

                                                return null;
                                              }
                                            )}
                                        </Grid>
                                      )}

                                    {/* Issues */}
                                    <Grid container spacing={1}>
                                      <h3 className={classes.issuesTitle}>
                                        {t(
                                          "components:VideoTutorial.downloadAssembled"
                                        )}
                                      </h3>
                                    </Grid>
                                    <VideoIssuesContainer
                                      pack={ship}
                                    ></VideoIssuesContainer>
                                  </Grid>
                                );
                              } else if (index === indexPackage) {
                                return (
                                  <Grid
                                    container
                                    className={classes.packageContainer}
                                  >
                                    <Grid item xs={12} md={12}>
                                      <Box p={1}>
                                        <span
                                          className={classes.packageSequence}
                                        >
                                          {t(
                                            "components:AssemblyTutorials.packageTitle",
                                            {
                                              number: ship.shipment,
                                            }
                                          )}
                                        </span>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="flex-start"
                                        flexWrap="wrap"
                                      >
                                        <VideoItemGallery
                                          className={classes.videoBox}
                                          pack={ship}
                                        ></VideoItemGallery>
                                      </Box>
                                    </Grid>

                                    {/* 12 plan - download magazine */}
                                    {currentOrder &&
                                      currentOrder.variantAttributes.plan ===
                                      "12" && (
                                        <Grid
                                          container
                                          justifyContent="space-between"
                                          alignItems="center"
                                          className={
                                            classes.instructionsContainer
                                          }
                                        >
                                          {/* Download the istructions */}
                                          {details &&
                                            details.issues &&
                                            details.issues.map(
                                              (issue, index) => {
                                                if (index === 0) {
                                                  return (
                                                    <Grid xs={12} md={4}>
                                                      <Grid
                                                        container
                                                        spacing={1}
                                                        alignItems="center"
                                                      >
                                                        <Grid xs={4} md={4}>
                                                          <Box
                                                            px={1}
                                                            className={
                                                              classes.instructionsGuide
                                                            }
                                                          >
                                                            <img
                                                              src={
                                                                issue?.image
                                                                  ?.src
                                                              }
                                                              alt={
                                                                issue.image.alt
                                                              }
                                                              title={
                                                                issue?.image
                                                                  ?.title
                                                              }
                                                            ></img>
                                                          </Box>
                                                        </Grid>
                                                        <Grid xs={8} md={8}>
                                                          <Box px={1}>
                                                            <h3
                                                              className={
                                                                classes.instructionsTitle
                                                              }
                                                            >
                                                              {t(
                                                                "components:VideoTutorial.downloadTitle"
                                                              )}
                                                            </h3>
                                                            <p
                                                              className={
                                                                classes.instructionsDesc
                                                              }
                                                            >
                                                              {t(
                                                                "components:VideoTutorial.downloadDesc"
                                                              )}
                                                            </p>

                                                            {window.location.href.includes(
                                                              "deagostini"
                                                            )
                                                              ? (currentOrder
                                                                ?.variantAttributes
                                                                ?.plan ===
                                                                "FK" ||
                                                                currentOrder
                                                                  ?.variantAttributes
                                                                  ?.plan ===
                                                                "12" ||
                                                                currentOrder?.offerType ===
                                                                "PREMIUM") &&
                                                              issue.magazine && (
                                                                <a
                                                                  style={{
                                                                    textDecoration:
                                                                      "none",
                                                                  }}
                                                                  href={
                                                                    issue
                                                                      ?.magazine
                                                                      ?.src ||
                                                                    ""
                                                                  }
                                                                  title={
                                                                    issue
                                                                      ?.magazine
                                                                      ?.title
                                                                  }
                                                                  alt={
                                                                    issue
                                                                      ?.magazine
                                                                      ?.alt ||
                                                                    ""
                                                                  }
                                                                >
                                                                  <Button
                                                                    className={
                                                                      classes.instructionsBtn
                                                                    }
                                                                  >
                                                                    <Icon name="download_tangerine"></Icon>
                                                                    <span>
                                                                      {t(
                                                                        "components:VideoTutorial.downloadCTA"
                                                                      )}
                                                                    </span>
                                                                  </Button>
                                                                </a>
                                                              )
                                                              : window.location.href.includes(
                                                                "fanhome"
                                                              ) &&
                                                              issue.magazine && (
                                                                <a
                                                                  style={{
                                                                    textDecoration:
                                                                      "none",
                                                                  }}
                                                                  href={
                                                                    issue
                                                                      ?.magazine
                                                                      ?.src ||
                                                                    ""
                                                                  }
                                                                  title={
                                                                    issue
                                                                      ?.magazine
                                                                      ?.title
                                                                  }
                                                                  alt={
                                                                    issue
                                                                      ?.magazine
                                                                      ?.alt ||
                                                                    ""
                                                                  }
                                                                >
                                                                  <Button
                                                                    className={
                                                                      classes.instructionsBtn
                                                                    }
                                                                  >
                                                                    <Icon name="download_tangerine"></Icon>
                                                                    <span>
                                                                      {t(
                                                                        "components:VideoTutorial.downloadCTA"
                                                                      )}
                                                                    </span>
                                                                  </Button>
                                                                </a>
                                                              )}
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }
                                            )}
                                          {currentOrder?.variantAttributes?.plan !== 'FK' && (
                                            <Grid xs={12} md={3}>
                                              <Box
                                                height="100%"
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                textAlign="right"
                                              >
                                                <h3
                                                  className={
                                                    classes.instructionsTitle
                                                  }
                                                >
                                                  {t(
                                                    "components:VideoTutorial.haveAssembled"
                                                  )}
                                                </h3>
                                                <Button onClick={completedClick}>
                                                  {t(
                                                    "components:VideoTutorial.mark"
                                                  )}
                                                </Button>
                                              </Box>
                                            </Grid>
                                          )}
                                        </Grid>
                                      )}

                                    {currentOrder &&
                                      currentOrder.variantAttributes.plan !==
                                      "FK" && (
                                        <>
                                          {/* Issues */}
                                          <Grid item xs={12} md={12}>
                                            <h3 className={classes.issuesTitle}>
                                              {t(
                                                "components:VideoTutorial.downloadAssembled"
                                              )}
                                            </h3>
                                          </Grid>
                                          <VideoIssuesContainer pack={ship} />
                                        </>
                                      )}
                                  </Grid>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </Grid>
                        </Container>
                      )}
                    </Grid>
                  )}

                {/* PACKAGE 12M */}

                {currentOrder &&
                  currentOrder.variantAttributes.plan === "12" && (
                    <Grid
                      container
                      className={[
                        classes.packageVideoContainer,
                        !hasSubscriptions ? classes.spaceBottom : "",
                      ]}
                      justifyContent="center"
                    >
                      {/* Package open */}
                      {shipments &&
                        shipments.open &&
                        shipments.open.length > 0 && (
                          <Container
                            container
                            maxWidth={"lg"}
                            className={classes.openVideoTutorialsContainer}
                          >
                            <Grid container justifyContent="center">
                              <Grid item xs={12} md={12}>
                                <h3 className={classes.packageTitle}>
                                  {t(
                                    "components:VideoTutorial.alreadyReceived"
                                  )}
                                </h3>
                              </Grid>

                              {/* Package */}
                              {shipments.open.map((ship, index) => {
                                if (indexPackage === "all") {
                                  return (
                                    <Grid container>
                                      <Grid item xs={12} md={12}>
                                        <Box p={1}>
                                          <span
                                            className={classes.packageSequence}
                                          >
                                            {t(
                                              "components:AssemblyTutorials.packageTitle",
                                              {
                                                number: ship.sequence,
                                              }
                                            )}
                                          </span>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="flex-start"
                                          flexWrap="wrap"
                                        >
                                          <VideoItemGallery
                                            className={classes.videoBox}
                                            pack={ship}
                                          ></VideoItemGallery>
                                        </Box>
                                      </Grid>

                                      {/* 12 plan - download magazine */}
                                      {currentOrder &&
                                        currentOrder.variantAttributes.plan ===
                                        "12" && (
                                          <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            className={
                                              classes.instructionsContainer
                                            }
                                          >
                                            {/* Download the istructions */}
                                            {details &&
                                              details.issues &&
                                              details.issues.map(
                                                (issue, index) => {
                                                  if (index === 0) {
                                                    return (
                                                      <Grid xs={12} md={4}>
                                                        <Grid
                                                          container
                                                          spacing={1}
                                                          alignItems="center"
                                                        >
                                                          <Grid xs={4} md={4}>
                                                            <Box
                                                              px={1}
                                                              className={
                                                                classes.instructionsGuide
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  issue?.image
                                                                    ?.src
                                                                }
                                                                alt={
                                                                  issue.image
                                                                    .alt
                                                                }
                                                                title={
                                                                  issue?.image
                                                                    ?.title
                                                                }
                                                              ></img>
                                                            </Box>
                                                          </Grid>
                                                          <Grid xs={8} md={8}>
                                                            <Box px={1}>
                                                              <h3
                                                                className={
                                                                  classes.instructionsTitle
                                                                }
                                                              >
                                                                {t(
                                                                  "components:VideoTutorial.downloadTitle"
                                                                )}
                                                              </h3>
                                                              <p
                                                                className={
                                                                  classes.instructionsDesc
                                                                }
                                                              >
                                                                {t(
                                                                  "components:VideoTutorial.downloadDesc"
                                                                )}
                                                              </p>

                                                              {window.location.href.includes(
                                                                "deagostini"
                                                              )
                                                                ? (currentOrder
                                                                  ?.variantAttributes
                                                                  ?.plan ===
                                                                  "FK" ||
                                                                  currentOrder
                                                                    ?.variantAttributes
                                                                    ?.plan ===
                                                                  "12" ||
                                                                  currentOrder?.offerType ===
                                                                  "PREMIUM") &&
                                                                issue.magazine && (
                                                                  <a
                                                                    style={{
                                                                      textDecoration:
                                                                        "none",
                                                                    }}
                                                                    href={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.src ||
                                                                      ""
                                                                    }
                                                                    title={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.title
                                                                    }
                                                                    alt={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.alt
                                                                    }
                                                                  >
                                                                    <Button
                                                                      className={
                                                                        classes.instructionsBtn
                                                                      }
                                                                    >
                                                                      <Icon name="download_tangerine"></Icon>
                                                                      <span>
                                                                        {t(
                                                                          "components:VideoTutorial.downloadCTA"
                                                                        )}
                                                                      </span>
                                                                    </Button>
                                                                  </a>
                                                                )
                                                                : window.location.href.includes(
                                                                  "fanhome"
                                                                ) &&
                                                                issue.magazine && (
                                                                  <a
                                                                    style={{
                                                                      textDecoration:
                                                                        "none",
                                                                    }}
                                                                    href={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.src ||
                                                                      ""
                                                                    }
                                                                    title={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.title
                                                                    }
                                                                    alt={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.alt
                                                                    }
                                                                  >
                                                                    <Button
                                                                      className={
                                                                        classes.instructionsBtn
                                                                      }
                                                                    >
                                                                      <Icon name="download_tangerine"></Icon>
                                                                      <span>
                                                                        {t(
                                                                          "components:VideoTutorial.downloadCTA"
                                                                        )}
                                                                      </span>
                                                                    </Button>
                                                                  </a>
                                                                )}
                                                            </Box>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  } else {
                                                    return false;
                                                  }
                                                }
                                              )}
                                            {currentOrder?.variantAttributes?.plan !== 'FK' && (
                                              <Grid xs={12} md={3}>
                                                <Box
                                                  height="100%"
                                                  display="flex"
                                                  flexDirection="column"
                                                  justifyContent="flex-end"
                                                  textAlign="right"
                                                >
                                                  <h3
                                                    className={
                                                      classes.instructionsTitle
                                                    }
                                                  >
                                                    {t(
                                                      "components:VideoTutorial.haveAssembled"
                                                    )}
                                                  </h3>
                                                  <ButtonMarked
                                                    pack={ship}
                                                  ></ButtonMarked>
                                                </Box>
                                              </Grid>
                                            )}
                                          </Grid>
                                        )}

                                      {/* Issues */}
                                      <Grid container spacing={1}>
                                        <h3 className={classes.issuesTitle}>
                                          {t(
                                            "components:VideoTutorial.downloadAssembled"
                                          )}
                                        </h3>
                                      </Grid>
                                      <VideoIssuesContainer
                                        pack={ship}
                                      ></VideoIssuesContainer>
                                    </Grid>
                                  );
                                } else if (index === indexPackage) {
                                  return (
                                    <Grid
                                      container
                                      className={classes.packageContainer}
                                    >
                                      <Grid item xs={12} md={12}>
                                        <Box p={1}>
                                          <span
                                            className={classes.packageSequence}
                                          >
                                            {t(
                                              "components:AssemblyTutorials.packageTitle",
                                              {
                                                number: ship.sequence,
                                              }
                                            )}
                                          </span>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <Box
                                          display="flex"
                                          flexDirection="row"
                                          justifyContent="flex-start"
                                          flexWrap="wrap"
                                        >
                                          <VideoItemGallery
                                            className={classes.videoBox}
                                            pack={ship}
                                          ></VideoItemGallery>
                                        </Box>
                                      </Grid>

                                      {/* 12 plan - download magazine */}
                                      {currentOrder &&
                                        currentOrder.variantAttributes.plan ===
                                        "12" && (
                                          <Grid
                                            container
                                            justifyContent="space-between"
                                            alignItems="center"
                                            className={
                                              classes.instructionsContainer
                                            }
                                          >
                                            {/* Download the istructions */}
                                            {details &&
                                              details.issues &&
                                              details.issues.map(
                                                (issue, index) => {
                                                  if (index === 0) {
                                                    return (
                                                      <Grid xs={12} md={4}>
                                                        <Grid
                                                          container
                                                          spacing={1}
                                                          alignItems="center"
                                                        >
                                                          <Grid xs={4} md={4}>
                                                            <Box
                                                              px={1}
                                                              className={
                                                                classes.instructionsGuide
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  issue?.image
                                                                    ?.src
                                                                }
                                                                alt={
                                                                  issue.image
                                                                    .alt
                                                                }
                                                                title={
                                                                  issue?.image
                                                                    ?.title
                                                                }
                                                              ></img>
                                                            </Box>
                                                          </Grid>
                                                          <Grid xs={8} md={8}>
                                                            <Box px={1}>
                                                              <h3
                                                                className={
                                                                  classes.instructionsTitle
                                                                }
                                                              >
                                                                {t(
                                                                  "components:VideoTutorial.downloadTitle"
                                                                )}
                                                              </h3>
                                                              <p
                                                                className={
                                                                  classes.instructionsDesc
                                                                }
                                                              >
                                                                {t(
                                                                  "components:VideoTutorial.downloadDesc"
                                                                )}
                                                              </p>

                                                              {(currentOrder
                                                                ?.variantAttributes
                                                                ?.plan ===
                                                                "FK" ||
                                                                currentOrder
                                                                  ?.variantAttributes
                                                                  ?.plan ===
                                                                "12" ||
                                                                currentOrder?.offerType ===
                                                                "PREMIUM") &&
                                                                issue.magazine && (
                                                                  <a
                                                                    style={{
                                                                      textDecoration:
                                                                        "none",
                                                                    }}
                                                                    href={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.src ||
                                                                      ""
                                                                    }
                                                                    title={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.title
                                                                    }
                                                                    alt={
                                                                      issue
                                                                        ?.magazine
                                                                        ?.alt
                                                                    }
                                                                  >
                                                                    <Button
                                                                      className={
                                                                        classes.instructionsBtn
                                                                      }
                                                                    >
                                                                      <Icon name="download_tangerine"></Icon>
                                                                      <span>
                                                                        {t(
                                                                          "components:VideoTutorial.downloadCTA"
                                                                        )}
                                                                      </span>
                                                                    </Button>
                                                                  </a>
                                                                )}
                                                            </Box>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    );
                                                  } else {
                                                    return false;
                                                  }
                                                }
                                              )}
                                            {currentOrder?.variantAttributes?.plan !== 'FK' && (
                                              <Grid xs={12} md={3}>
                                                <Box
                                                  height="100%"
                                                  display="flex"
                                                  flexDirection="column"
                                                  justifyContent="flex-end"
                                                  textAlign="right"
                                                >
                                                  <h3
                                                    className={
                                                      classes.instructionsTitle
                                                    }
                                                  >
                                                    {t(
                                                      "components:VideoTutorial.haveAssembled"
                                                    )}
                                                  </h3>

                                                  <ButtonMarked
                                                    pack={ship}
                                                  ></ButtonMarked>
                                                </Box>
                                              </Grid>
                                            )}
                                          </Grid>
                                        )}

                                      {currentOrder &&
                                        currentOrder.variantAttributes.plan !==
                                        "FK" && (
                                          <>
                                            {/* Issues */}
                                            <Grid item xs={12} md={12}>
                                              <h3
                                                className={classes.issuesTitle}
                                              >
                                                {t(
                                                  "components:VideoTutorial.downloadAssembled"
                                                )}
                                              </h3>
                                            </Grid>
                                            <VideoIssuesContainer
                                              pack={ship}
                                            ></VideoIssuesContainer>
                                          </>
                                        )}
                                    </Grid>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </Grid>
                          </Container>
                        )}
                    </Grid>
                  )}
              </Container>
            </Fragment>
          ) : (
            // Message if there's not closed package
            <Container style={{ marginTop: "30px" }}>
              <Box className={classes.noPackageClosed}>
                <Text variant="titleXL" weight="bold">
                  {t("common:noPackageClosedfirst")}
                </Text>
                <Text>{t("common:noPackageClosedsecond")}</Text>
              </Box>
            </Container>
          )}

          {/* Help center redirect */}
          <Service
            mainTitle={t("components:VideoTutorial.serviceTitle")}
            description={t("components:VideoTutorial.helpYou")}
            icon="help-center"
            btnText={t("components:VideoTutorial.linkTitle")}
            href={`/${site}/myarea/help-center/`}
            className={classes.serviceContainer}
          ></Service>

          {currentOrder && currentOrder.variantAttributes.plan !== "FK" && (
            <Box
              className={[
                !hasSubscriptions ? classes.spaceBottom : "",
                classes.giftBoxes,
              ]}
            >
              {/* Gifts */}
              {(nextShipment && nextShipment.shipment) ||
                (shipments && shipments.open && shipments.open.length > 1 && (
                  <Grid item xs={12} md={12}>
                    <h3 className={classes.mainTitle}>
                      {t("components:VideoTutorial.getReady")}
                    </h3>
                  </Grid>
                ))}
              <Grid item xs={12} md={12}>
                <Box>
                  <Swiper
                    className="mySwiper"
                    breakpoints={swiperResponsive}
                    slidesPerView={1.1}
                    spaceBetween={3}
                    centeredSlides={true}
                  >
                    {/* TODO check why nextShipment doesn't work */}
                    {nextShipment && nextShipment.shipment && (
                      <SwiperSlide>
                        <GiftItemVideo pack={nextShipment}></GiftItemVideo>
                      </SwiperSlide>
                    )}

                    {shipments &&
                      shipments.open &&
                      shipments.open.length > 0 &&
                      shipments.open.map((ship) => {
                        return (
                          <SwiperSlide>
                            {/* TODO aggiungere il nextship */}
                            <GiftItemVideo pack={ship}></GiftItemVideo>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </Box>
              </Grid>
            </Box>
          )}
        </div>
      )}

      {!subscriptions?.newsletter && sitesId.us === user.acquisitionOwner && (
        <div id={'Collection-emailComunication-subscribe'}>
          <SubscribeForm
            email={user.email}
            uid={user.UID}
            redirectUrl={homePage ? homePage.href : ''}
            firstName={user.name}
            lastName={user.lastName}
            stayInMyArea
            disabled={false}
          />
        </div>
      )}
    </React.Fragment>
  );
});

export default Tutorials;
