import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { Grid, Hidden, MenuItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import {
  EditablePanel,
  EditablePanelDetails,
  EditablePanelSummary,
  EditablePanelActions,
  EditablePanelActionsCancelOnly,
} from './common/EditablePanel';
import Text from './common/Text';
import ProfileElement from './common/ProfileElement';
import Alert from './common/Alert';
import Input from './common/Input';
import Select from './common/Select';
import Icon from './common/Icon';
import { useStores } from '../stores';
import PhoneInput from './PhoneInput';
import appStyles from '../../../config/appStyles.js';

const useStyles = makeStyles((theme) => appStyles.profileInfo(theme));

const ProfileInfo = observer((props) => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'components', 'validations']);
  const { userStore } = useStores();
  const { user } = userStore;
  const [userInfo, setUserInfo] = useState(user);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalIcon, setModalIcon] = useState();
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [showActualPass, setShowActualPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmationPass, setConfirmationPass] = useState(false);
  const [dateInputValue, setDateInputValue] = useState(
    userInfo.birthYear && userInfo.birthMonth && userInfo.birthDay
      ? `${userInfo.birthYear}-${String(userInfo.birthMonth).padStart(2, '0')}-${String(userInfo.birthDay).padStart(2, '0')}`
      : ''
  );

  const updateField = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const confirmPasswordError = useMemo(
    () => newPassword && newPassword !== confirmPassword,
    [newPassword, confirmPassword],
  );
  const insertNewPasswordError = useMemo(
    () => !newPassword && password,
    [newPassword, password],
  );
  const passwordRequired = useMemo(
    () => newPassword !== '' && newPassword !== undefined && !password,
    [newPassword, password],
  );

  const handleSubmit = (evt, cb) => {
    evt.preventDefault();
    const updatedUserInfo = { ...userInfo, newPassword, password };
    userStore.updateUserData(updatedUserInfo, openModal, cb);
    userStore.setShowModal(false);
  };

  const handleCancel = () => {
    setConfirmPassword('');
    setPassword('');
    setNewPassword('');
    setUserInfo({ ...user });
  };

  const openModal = (text, isError) => {
    setShowModal(true);
    setModalText(text);
    setModalIcon(isError ? <WarningIcon /> : <CheckCircleIcon />);
  };

  const clearField = () => {
    document.getElementById('error-current-psw').remove();
    document
      .getElementById('id-currentPassword')
      .parentNode.style.removeProperty('border');
    document.getElementById('id-currentPassword').value = '';
  };

  const handleBirthdayChange = (e) => {
    const dateValue = e.target.value;
    setDateInputValue(dateValue);
    const [year, month, day] = dateValue.split('-');

    if (year.length === 4 && month.length === 2 && day.length === 2) {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        birthYear: parseInt(year, 10) || undefined,
        birthMonth: parseInt(month, 10) || undefined,
        birthDay: parseInt(day, 10) || undefined,
      }));
    }
  };

  return (
    <div id={props.id + '-profile-info-container'} className={classes.root}>
      <span className={classes.sectionTitle}>
        {t('components:ProfileInfo.title')}
      </span>

      <EditablePanel
        className={classes.dataContainer}
        actionsEnabled={props.actionsEnabled}
        onEdit={props.onEdit}
        onSubmit={handleSubmit}>
        <EditablePanelSummary
          boxName="personalInfo"
          title={t('components:ProfileInfo.title')}
          mobileTitle={t('components:ProfileInfo.title')}
          className={!props.actionsEnabled ? classes.panelSummaryBorder : ''}>
          <Grid className={classes.summaryContainer} container justify="center">
            <Grid item xs={12} md>
              <div className={classes.avatarContainer}>
                <Avatar
                  className={classes.avatar}
                  alt={user.name + ' ' + user.lastName}
                  src={
                    user.smallAvatar
                      ? user.smallAvatar
                      : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                  }
                />
                <ProfileElement
                  className={classes.nameInfo}
                  first
                  label={t('components:ProfileInfo.profileElementName')}
                  value={user.name + ' ' + user.lastName}
                />
              </div>
            </Grid>
            <Grid item xs={12} md>
              <ProfileElement
                className={classes.profileContainer}
                label={t('components:ProfileInfo.phone')}
                value={user.phone}
              />
            </Grid>
            <Grid item xs={12} md>
              <ProfileElement
                className={classes.profileContainer}
                label={t('components:ProfileInfo.email')}
                value={user.email}
              />
            </Grid>
            <Grid item xs={12} md>
              <ProfileElement
                className={classes.profileContainer}
                label={t('components:ProfileInfo.password')}
                value={_.times(8, (i) => (
                  <span key={i}>&bull;</span>
                ))}
              />
            </Grid>

            {!props.actionsEnabled && (
              <EditablePanelActionsCancelOnly
                cancel={handleCancel}
                cancelLabel={t('components:ProfileInfo.cancelLabel')}
                disabledProp={
                  (!confirmPassword && !newPassword && password) ||
                  (!confirmPassword && newPassword && !password) ||
                  (confirmPassword && !newPassword && !password) ||
                  (newPassword && newPassword === password) ||
                  (newPassword && newPassword !== confirmPassword)
                }
              />
            )}
          </Grid>
        </EditablePanelSummary>
        <EditablePanelDetails className={classes.border}>
          {/* <ValidatorForm> */}
          <Grid container className={classes.containerProf}>
            <Grid className={classes.col} xs={12} md={6} item>
              <div id={props.id + '-profile-info-data'}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Text
                      className={classes.colTitle}
                      weight="bold"
                      variant="titleXL">
                      {t('components:ProfileInfo.profileData')}
                    </Text>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      className={classes.inputProf}
                      validators={['required']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:ProfileInfo.name'),
                        }),
                      ]}
                      inputProps={{
                        pattern: '[A-Za-z ]+',
                      }}
                      label={t('components:ProfileInfo.name')}
                      name={'name'}
                      value={userInfo.name}
                      onChange={updateField}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      className={classes.inputProf}
                      validators={['required']}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:ProfileInfo.lastName'),
                        }),
                      ]}
                      label={t('components:ProfileInfo.lastName')}
                      name={'lastName'}
                      value={userInfo.lastName}
                      onChange={updateField}
                      type={'text'}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <PhoneInput
                      label={t('components:ProfileInfo.phone')}
                      validators={['required', 'isTelephoneValid']}
                      value={userInfo.phone}
                      errorMessages={[
                        t('validations:requiredField', {
                          name: t('components:ProfileInfo.phone'),
                        }),
                        t('validations:phoneIsNotValid'),
                      ]}
                      onChange={(e) => {
                        updateField(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      className={classes.inputProf}
                      label={t('components:ProfileInfo.email')}
                      name={'email'}
                      value={userInfo.email}
                      type={'email'}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      className={classes.indexSelect}
                      label={t('components:ProfileInfo.gender')}
                      name="gender"
                      value={userInfo.gender || ''}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          gender: e.target.value,
                        });
                      }}
                    >
                      {Object.entries(t('components:ProfileInfo.genders', { returnObjects: true })).map(
                        ([key, label]) => {
                          const genderMap = {
                            male: 'm',
                            female: 'f',
                            unknown: 'u',
                          };
                          return (
                            <MenuItem key={key} value={genderMap[key]}>
                              {label}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      className={classes.dateInput}
                      label={t('components:ProfileInfo.birthday')}
                      name="birthday"
                      value={dateInputValue}
                      onChange={handleBirthdayChange}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.changePassContainer}>
                  <span
                    onClick={() => setIsPassVisible(!isPassVisible)}
                    className={classes.changePass}>
                    {t('components:ChangePassword.title')}
                  </span>
                </Grid>
              </div>
            </Grid>
            {isPassVisible && (
              <Grid
                className={[classes.col, classes.colPass]}
                xs={12}
                md={6}
                item>
                <div id={props.id + '-profile-info-passwords'}>
                  <Grid spacing={1} container>
                    <Grid item xs={12}>
                      <Text
                        className={classes.colTitle}
                        weight="bold"
                        variant="titleXL">
                        {t('components:ProfileInfo.password')}
                      </Text>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                          <Input
                            className={classes.inputProf}
                            id={'id-currentPassword'}
                            // validators={
                            //     passwordRequired
                            //         ? [
                            //               "isPasswordMatch",
                            //               "required",
                            //           ]
                            //         : []
                            // }
                            validators={['required']}
                            errorMessages={[t('validations:requiredField')]}
                            label={t('components:ProfileInfo.currentPassword')}
                            name={'password'}
                            value={password}
                            error={passwordRequired}
                            InputProps={{
                              endAdornment: (
                                <div
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    setShowActualPass(!showActualPass)
                                  }>
                                  {showActualPass ? (
                                    <Icon
                                      className={classes.iconPass}
                                      name="icon-password"></Icon>
                                  ) : (
                                    <Icon
                                      className={classes.iconPass}
                                      name="icon-password-disabled"></Icon>
                                  )}
                                </div>
                              ),
                            }}
                            helperText={
                              passwordRequired
                                ? t('validations:requiredField')
                                : ''
                            }
                            inputProps={{
                              autocomplete: 'new-password',
                            }}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            //onChange={updateField}
                            type={showActualPass ? 'text' : 'password'}
                            onClick={() =>
                              document.getElementById('error-current-psw') !== undefined
                                ? clearField()
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={12} item>
                      <Input
                        className={classes.inputProf}
                        validators={
                          password !== '' && password !== undefined
                            ? ['required']
                            : []
                        }
                        label={t('components:ProfileInfo.newPassword')}
                        name={'newPassword'}
                        value={newPassword}
                        inputProps={{
                          autocomplete: 'new-password',
                        }}
                        InputProps={{
                          endAdornment: (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => setShowNewPass(!showNewPass)}>
                              {showNewPass ? (
                                <Icon
                                  className={classes.iconPass}
                                  name="icon-password"></Icon>
                              ) : (
                                <Icon
                                  className={classes.iconPass}
                                  name="icon-password-disabled"></Icon>
                              )}
                            </div>
                          ),
                        }}
                        //onChange={updateField}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        error={insertNewPasswordError}
                        helperText={
                          insertNewPasswordError
                            ? t('validations:requiredField')
                            : ''
                        }
                        type={showNewPass ? 'text' : 'password'}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Input
                        className={classes.inputProf}
                        validators={
                          newPassword !== '' && newPassword !== undefined
                            ? ['required']
                            : []
                        }
                        label={t('components:ProfileInfo.confirmPassword')}
                        name={'newPassword'}
                        inputProps={{
                          autocomplete: 'new-password',
                        }}
                        InputProps={{
                          endAdornment: (
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                setConfirmationPass(!showConfirmationPass)
                              }>
                              {showConfirmationPass ? (
                                <Icon
                                  className={classes.iconPass}
                                  name="icon-password"></Icon>
                              ) : (
                                <Icon
                                  className={classes.iconPass}
                                  name="icon-password-disabled"></Icon>
                              )}
                            </div>
                          ),
                        }}
                        value={confirmPassword}
                        error={confirmPasswordError}
                        helperText={
                          confirmPasswordError
                            ? t('validations:passwordNotMatch')
                            : ''
                        }
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        type={showConfirmationPass ? 'text' : 'password'}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} item>
                    {newPassword && newPassword === password && (
                      <div className={classes.errorPass}>
                        {t('validations:passwordCannotMatch')}
                      </div>
                    )}
                  </Grid>
                </div>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              className={[classes.col, classes.colPass]}>
              <Hidden smDown>
                <EditablePanelActions
                  className={classes.panelActions}
                  cancel={handleCancel}
                  cancelLabel={t('components:ProfileInfo.cancelLabel')}
                  disabledProp={
                    (!confirmPassword && !newPassword && password) ||
                    (!confirmPassword && newPassword && !password) ||
                    (confirmPassword && !newPassword && !password) ||
                    (newPassword && newPassword === password) ||
                    (newPassword && newPassword !== confirmPassword)
                  }
                />
              </Hidden>
            </Grid>
          </Grid>
          {/* </ValidatorForm> */}
        </EditablePanelDetails>
        <Hidden mdUp>
          <EditablePanelActions
            className={classes.panelActionsMobile}
            cancel={handleCancel}
            cancelLabel={t('components:ProfileInfo.cancelLabel')}
            disabledProp={
              (!confirmPassword && !newPassword && password) ||
              (!confirmPassword && newPassword && !password) ||
              (confirmPassword && !newPassword && !password) ||
              (newPassword && newPassword === password) ||
              (newPassword && newPassword !== confirmPassword)
            }
          />
        </Hidden>
      </EditablePanel>
      <Alert
        id="profile-info-alert"
        open={showModal}
        onClose={() => setShowModal(false)}
        text={modalText}
        icon={modalIcon}
        title={t('common:success')}
      />
    </div>
  );
});

export default ProfileInfo;
