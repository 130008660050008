import React, { useEffect, useState } from 'react';
import { useStores } from '../stores';
import UserBanner from './UserBanner';
import InfoBar from './common/InfoBar';
import Tabs from './Tabs';
import { useTranslation } from 'react-i18next';
import { countryCodes } from '../../../stores/Constants';

const Header = (props) => {
  const { userStore } = useStores();
  const { isLogged } = userStore;
  const { t } = useTranslation('components');
  const [show, setShow] = useState(true);
  const { uiStore, orderStore } = useStores();
  const country = uiStore.country.toLowerCase() || uiStore.site.toLowerCase();

  const handleClose = () => {
    setShow(false);
    localStorage.setItem('privacy_update_alert_closed', true);
  };

  const getCurrentTimestamp = (dateString) => {
    const date = new Date(dateString);
    const timestamp = date.getTime();

    return timestamp;
  };

  return (
    <>
      {isLogged && (
        <>
          {country !== countryCodes.us && process.env.REACT_APP_BRAND === 'FH' ? (
            <InfoBar
              activeFrom={getCurrentTimestamp('2023-05-10')}
              activeTo={getCurrentTimestamp('2023-08-31')}
              title={t(`components:PrivacyUpdateAlert.title`)}
              message={t(`components:PrivacyUpdateAlert.message`)}
              onClose={handleClose}
              open={show}
            />
          ) : (
            <InfoBar
              activeFrom={getCurrentTimestamp('2023-05-10')}
              activeTo={getCurrentTimestamp('2023-07-31')}
              title={t(`components:PrivacyUpdateAlert.title`)}
              message={t(`components:PrivacyUpdateAlert.message`)}
              onClose={handleClose}
              open={show}
            />
          )}
          <UserBanner />
          <Tabs />
        </>
      )}
    </>
  );
};

export default Header;
