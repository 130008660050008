/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Container } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import Text from "../components/common/Text";
import Button from "./common/Button";
import Input from "./common/Input";
import axios from "axios";
import clsx from "clsx";
import { useStores } from "../stores";
import appStyles from "../../../config/appStyles.js";
import gtm from "../../../utils/gtm";

const useStyles = makeStyles((theme) => appStyles.subscribeForm(theme));

const SubscribeForm = observer((props) => {
  const {
    email,
    firstName,
    lastName,
    uid,
    redirectUrl,
    stayInMyArea,
    disabled,
  } = props;
  const { t } = useTranslation(["common", "components", "validations"]);
  const classes = useStyles();
  const { uiStore, userStore } = useStores();
  const [news, setNews] = useState(null);

  const [unsubscribeReq, setUnsubscribeReq] = useState({
    comunicationType: "all",
    reasonType: "",
    firstName: firstName || "",
    lastName: lastName || "",
    email: "" + email,
  });

  useEffect(() => {
    if ((email, uid)) {
      userStore.fetchSubscriptions(email, uid);
    }
  }, [email, uid]);

  const handleChange = (e) => {
    setUnsubscribeReq({
      ...unsubscribeReq,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    // const subscriptions = subs;
    const subscriptions = { ...userStore.subscriptions };

    for (var key in subscriptions) {
      if (key === "newsletter") {
        subscriptions[key] = true;
      }
    }
    gtm.pushEvent("try_send", "subscribeToNewsletter");

    try {
      uiStore.setLoader(true);
      await userStore.subscribe({
        firstName: unsubscribeReq.firstName,
        lastName: unsubscribeReq.lastName,
        email: unsubscribeReq.email,
        subscriptions,
        audience: {
          group: 'newsletter',
          source: 'organic',
        },
      });
      gtm.pushEvent("success", "subscribeToNewsletter");

      uiStore.setLoader(false);
      uiStore.openModal(
        t("components:Subscribe.successfullModal.text"),
        t("components:Subscribe.successfullModal.title"),
        {
          isError: false,
          confirmLabel: t(
            stayInMyArea
              ? "components:Subscribe.successfullModal.closeWindow"
              : "components:Subscribe.successfullModal.confirmButton"
          ),
          onConfirm: () => {
            if (stayInMyArea) {
              uiStore.closeModal();
            } else {
              window.location = redirectUrl;
            }
          },
        }
      );
    } catch (err) {
      uiStore.openModal(err.errorDetails, "", { isError: true });
    }
  };

  useEffect(() => {
    const instance = axios.create({
      timeout: 1000,
      headers: {
        Authorization: "Bearer " + "huf43yhrevieu43erhvb74vjksdnv",
      },
    });

    instance
      .get(process.env.REACT_APP_ENDPOINT_SETTINGS + uiStore.site)
      .then((response) => {
        let news = response.data.data.content[uiStore.site];
        news = news[0].data.newsletter.repository;
        setNews(news);
      })
      .catch(() => setNews(null));
  }, []);

  return (
    <>
      {news && (<div className={classes.formContainer}>
        <ValidatorForm
          id="subscribe-form"
          style={{ width: "100%" }}
          onSubmit={handleSubmit}
        >
          <Container container>
            <Grid xs={12} md={12} item>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Text className={classes.mainTitle}>
                  {news?.title?.field_value}
                </Text>
                <Text className={classes.subtitle}>
                  {news?.subtitle?.field_value}
                </Text>
                <Text className={classes.subtitleNew}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: news?.privacy_info?.field_value,
                    }}
                  />
                </Text>
              </Box>
            </Grid>
            <Grid xs={12} md={12} item>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <div className={classes.inputDiv}>
                    <Input
                      disabled={true}
                      className={classes.inputCustom}
                      validators={["required"]}
                      errorMessages={[
                        t("validations:requiredField", {
                          name: t("components:Subscribe.email"),
                        }),
                      ]}
                      name={"email"}
                      value={unsubscribeReq.email}
                      onChange={handleChange}
                    />
                  </div>
                </Grid>
                <Grid xs={12} md={12} item>
                  <div className={clsx(classes.actions)}>
                    <Button
                      disabled={disabled}
                      type="submit"
                      onClick={() =>
                        gtm.pushEvent("start", "subscribeToNewsletter")
                      }
                      className={clsx(classes.submitButton)}
                      size="large"
                    >
                      {news?.button_text?.field_value}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </ValidatorForm>
      </div>)}
    </>
  );
});

export default SubscribeForm;
