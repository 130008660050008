import React, { useState, useEffect } from "react";
import { useStores } from "./core/src/stores";
import { observer } from "mobx-react";
import Header from "./template/Header";
import axios from "axios";

const AppBar = observer((props) => {
    const { pageStore } = useStores();

    const { uiStore } = useStores();
    const [headerData, setHeaderData] = useState({});

    useEffect(() => {
        pageStore.fetchPages();
    }, [pageStore]);

    useEffect(() => {
        async function fetchData() {
            const resultHeader = await getHeaderData(
                "headerv2",
                uiStore.site ? uiStore.site : "uk",
            );

            setHeaderData(resultHeader.data.data.content);
        }
        fetchData();
    }, [uiStore.site]);

    const getHeaderData = async (type, country) => {
        const endpoint =
            process.env.REACT_APP_CMS_ENDPOINT +
            process.env.REACT_APP_GET_MODULES || "";

        return await axios
            .get(`${endpoint}?type=${type}&country=${country}`)
            .then((res) => res);
    };

    return <Header {...headerData} />
});

export default AppBar;
