import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid } from '@material-ui/core';
import Progress from '../components/common/Progress';
import Text from '../components/common/Text';
import SubscriptionDetails from '../components/SubscriptionDetails';
import PaymentResult from '../components/common/PaymentResult';
import { useStores } from '../stores';
import appStyles from '../../../config/appStyles.js';
import SubscribeForm from '../components/SubscribeForm';
import PackageFullKit from '../components/Package/PackageFullKit';
import PackageRegular from '../components/Package/PackageRegular';
import { sitesId } from '../../../stores/Constants';
import PackageNextDelivery from '../components/Package/PackageNextDelivery.js';
import { statusType } from '../constants.js';

const useStyles = makeStyles((theme) => appStyles.subscription(theme));

const Subscription = observer(() => {
  const REACT_APP_GET_PRODUCT_INFO = process.env.REACT_APP_GET_PRODUCT_INFO;
  const classes = useStyles();
  const { t } = useTranslation();
  const { uiStore, orderStore, userStore } = useStores();
  const [availableInvoice, setAvailableInvoice] = useState(false);
  const [productInfo, setProductInfo] = useState(null);
  const [tickets, setTickets] = useState([]);

  const {
    isLoadingShipments,
    currentOrder,
    shipments,
    nextShipment,
    orderTypes,
  } = orderStore;

  const { user, subscriptions } = userStore;
  const homePage = t('components:Footer.homePage', {
    returnObjects: true,
    defaultValue: {},
  });

  useEffect(() => {
    if (currentOrder === orderTypes.backissue) {
      orderStore.fetchBkiShipments();
    } else {
      orderStore.fetchShipments();
      fetch(`${process.env.REACT_APP_ZENDESK_API_TICKET_URL}${currentOrder.id}`)
        .then((res) => res.json())
        .then((res) => {
          setTickets(res?.results);
        })
        .catch(() => setTickets([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);

  useEffect(() => {
    if (shipments && shipments.closed && shipments.closed.length === 0) {
      let shipInvoice = [];
      shipments.closed.forEach((ship) => {
        orderStore
          .checkInvoiceAvailability(ship)
          .then((has) => shipInvoice.push(has));
      });

      setAvailableInvoice(shipInvoice);
    }
  }, [shipments]);

  useEffect(() => {
    if (
      shipments?.open?.length === 0 &&
      currentOrder !== orderTypes.backissue
    ) {
      orderStore.fetchNextShipments();
      orderStore.fetchNextGift();
    }
    const getProductInfo = () => {
      axios
        .get(REACT_APP_GET_PRODUCT_INFO + currentOrder.variantId)
        .then((res) => setProductInfo(res.data))
        .catch(() => setProductInfo(null));
    };
    if (currentOrder !== orderTypes.backissue) {
      getProductInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder, shipments]);

  function findTicketByTag(arr, tag) {
    return arr.find((element) => element.tags.includes(tag));
  }

  return (
    <>
      <Container className={classes.paddingContainer}>
        {isLoadingShipments ? (
          <Progress />
        ) : (
          <Grid container>
            <Grid item xs={12} md={8} className={classes.packagesGrid}>
              {currentOrder &&
                (currentOrder?.variantAttributes?.plan !== 'FK' ||
                  currentOrder === orderTypes.backissue) &&
                shipments && (
                  <Box>
                    {nextShipment?.sequence &&
                      shipments?.open?.length === 0 &&
                      shipments?.closed[0].shipment.status.toLowerCase() ===
                      statusType.DELIVERED && (
                        <>
                          <Text
                            className={classes.sectionTitle}
                            variant="titleXXXL"
                            weight="bold"
                            gutterBottom>
                            {t('components:NextPackage.whoWillBeInNext')}
                          </Text>
                          <PackageNextDelivery
                            sequence={nextShipment.sequence}
                            gifts={nextShipment.gifts}
                            productType={currentOrder.productType}
                          />
                        </>
                      )}
                    {shipments?.open?.length > 0 && (
                      <>
                        <Text
                          className={classes.sectionTitle}
                          variant="titleXXXL"
                          weight="bold"
                          gutterBottom>
                          {t('components:NextPackage.whoWillBeInNext')}
                        </Text>
                        {shipments.open.map((ship, index) => {
                          return (
                            <PackageRegular
                              expandFirst={index + 1 === 1}
                              plan={
                                currentOrder &&
                                currentOrder?.variantAttributes?.plan
                              }
                              id={'open_package_' + ship.id}
                              ship={ship}
                              productInfo={productInfo}
                            />
                          );
                        })}
                      </>
                    )}

                    {shipments?.closed?.length > 0 && (
                      <>
                        <Text
                          className={classes.sectionTitle}
                          variant="titleXXXL"
                          weight="bold"
                          gutterBottom>
                          {t('yourReceivedPackages')}
                        </Text>
                        {shipments.closed.map((ship, index) => {
                          let ticket = null;
                          if (tickets.length > 0) {
                            ticket = findTicketByTag(tickets, ship.ref);
                          }

                          return (
                            <PackageRegular
                              expandFirst={
                                index + 1 === 1 &&
                                shipments &&
                                shipments.open &&
                                shipments.open.length <= 0
                              }
                              plan={
                                currentOrder &&
                                currentOrder?.variantAttributes?.plan
                              }
                              id={'closed_package_' + ship.id}
                              ship={ship}
                              productInfo={productInfo}
                              ticket={ticket}
                            />
                          );
                        })}
                      </>
                    )}
                  </Box>
                )}
            </Grid>

            {currentOrder && currentOrder?.variantAttributes?.plan === 'FK' && (
              <Grid item xs={12} md={12}>
                <Box mb={3}>
                  {shipments &&
                    ((shipments.open && shipments.open.length > 0) ||
                      (shipments.closed && shipments.closed.length > 0)) ? (
                    <PackageFullKit
                      shipments={shipments}
                      productInfo={productInfo}
                    />
                  ) : (
                    <Progress />
                  )}
                </Box>
              </Grid>
            )}

            {currentOrder.type !== orderTypes.backissue &&
              currentOrder !== orderTypes.backissue &&
              currentOrder?.variantAttributes?.plan !== 'FK' && (
                <Grid item xs={12} md={4}>
                  <Box mt={3} className={classes.subscriptionWrapper}>
                    <SubscriptionDetails
                      productId={currentOrder.productId}
                      subscription={currentOrder}
                      address={shipments && shipments.address}
                    />
                  </Box>
                </Grid>
              )}
          </Grid>
        )}
        <PaymentResult />
      </Container>
      {!subscriptions?.newsletter && sitesId.us === user.acquisitionOwner && (
        <div id={'Collection-emailComunication-subscribe'}>
          <SubscribeForm
            email={user.email}
            uid={user.UID}
            redirectUrl={homePage ? homePage.href : ''}
            firstName={user.name}
            lastName={user.lastName}
            stayInMyArea
            disabled={false}
          />
        </div>
      )}
    </>
  );
});

export default Subscription;
