import React, { useState } from "react";
import ProfileInfo from "../components/ProfileInfo";
import { useStores } from "../stores";
import MyAddresses from "../components/MyAddresses";
import EmailComunication from "../components/EmailComunication";
import { Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { sitesId } from "../../../stores/Constants";
import storage from "../utils/storage";
import Privacy from "./Privacy";

const useStyles = makeStyles((theme) => ({
    mainTitle: {
        paddingTop: "32px",
        paddingBottom: "40px",
        fontSize: 30,
        fontWeight: 600,
        margin: 0,
        color: theme.customPalette.darkishPurple,
        [theme.breakpoints.up("sm")]: {
            paddingTop: "56px",
        },
    },
}));

const Profile = () => {
    const classes = useStyles();
    const { t } = useTranslation(["common", "components", "validations"]);
    const [actionsEnabled, setActionsEnabled] = useState(true);
    const { numverifyStore, userStore } = useStores();
    const { user } = userStore;
    const handleExpansion = (expaded) => {
        setActionsEnabled(!expaded);
        numverifyStore.setShowPhoneError(false);
    };
    const isPasaUser = JSON.parse(storage.get("isPasaUser") || "false");

    return (
        <Container>
            <h2 className={classes.mainTitle}>
                {t("components:ProfileInfo.mainTitle")}
            </h2>
            <ProfileInfo
                id={"profile"}
                actionsEnabled={actionsEnabled}
                onEdit={handleExpansion}
            />
            <MyAddresses
                id={"profile"}
                actionsEnabled={actionsEnabled}
                onEdit={handleExpansion}
            />
            {user.acquisitionOwner === sitesId.us && (<EmailComunication
                id={"profile"}
                actionsEnabled={actionsEnabled}
                onEdit={handleExpansion}
            />)}
            {user.acquisitionOwner !== sitesId.us && !isPasaUser && <Privacy />}
        </Container>
    );
};

export default Profile;
